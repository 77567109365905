
.digit {
    display: inline-block;
    margin: 4px;
    min-width: 180px;
}

.digit .number {
    font-size: 107px;
    padding: 0 24px;
    background: rgba(40, 50, 61, .62);
    font-family: Roboto, sans-serif;
    font-weight: 300;
}

.digit .label {
    padding-bottom: 5px;
    font-size: 23px;
    text-transform: uppercase;
    background: rgba(40, 50, 61, .62);
    text-align: center;
}
