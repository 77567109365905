.thought {
    padding-left: 200px;
;}

.bubble {
    padding: 1em;
    width: auto;
    min-width: 200px;
    max-width: 400px;
    word-wrap: break-word;
    background: rgb(40, 50, 61);
    color: darkgray;
	display: block;
	margin: 0 auto;
	border-radius: 10px;
	font-size: xx-large;
    text-align: center;
    z-index: 100;
    display: inline-block;
}

.pointer {
	height: 40px;
	width: 40px;
    background: rgb(40, 50, 61);
	margin: 0 auto;
	transform: rotate(45deg);
	border-radius: 40px 0 10px 0;
	margin-top: -20px;
	position: relative;
    left: calc(0.5vw - 50px);
    z-index: 1;
}
